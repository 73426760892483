import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import PiwikPro from '@piwikpro/react-piwik-pro';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig'; // Import msalConfig from authConfig.ts


// PiwikPro Initialization
PiwikPro.initialize('34b6019e-5dd6-41bd-9b1b-a8f9e68904e6', 'https://brunel.piwik.pro');

// Create MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <MsalProvider instance={msalInstance}>
        <App />
    </MsalProvider>
);
