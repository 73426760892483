import { Configuration } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: "987fe89d-209f-4e9b-872b-1a6f6e798330", // Your Client ID
    authority: "https://login.microsoftonline.com/3681f279-453b-44f0-8026-720f4cb38bda/v2.0", // Your Tenant ID
    redirectUri: "/", // Your redirect URI
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true, // Set this to true for IE11
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case 0:
            console.error(message);
            return;
          case 1:
            console.warn(message);
            return;
          case 2:
            console.info(message);
            return;
          case 3:
            console.debug(message);
            return;
          case 4:
            console.trace(message);
            return;
        }
      }
    }
  }
};

export const loginRequest = {
  scopes: ["User.Read"]
};