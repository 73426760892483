import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { regionContent, regionInstructions, RegionId } from '../data/appData';

const Instructions: React.FC = () => {
  const { region } = useParams<{ region: string }>();

  if (!region || !(region in regionContent)) {
    return <div>Region not found</div>;
  }

  const validRegion = region as RegionId;
  const instructions = regionInstructions[validRegion];

  return (
    <div>
      <div className="header">
        <img alt="MyBrunel Logo" width="100" height="26" src="/images/brunel_logo.svg" />
      </div>
      <div className="description-container">
        <div className="button-container">
            <Link to={`/dashboard/${validRegion}`} className="button">Back</Link>
        </div>
        <h1>Instructions</h1>
        <p>These instructions are crafted as a comprehensive resource for both beginners and advanced users. With clear step-by-step guides and handy tips, we provide accessible instructions for various levels of experience.</p>
        <div className="app-container">
        {instructions.map((instruction, index) => (
          <a key={index} href={instruction.link} target="_blank" rel="noreferrer" className="instructions">
            <h3>{instruction.title}</h3>
            <p>{instruction.description}</p>
            <img src={instruction.logoSrc} className="powered-by-logo" alt={instruction.logoAlt} />
          </a>
        ))}
      </div>
      </div>
      <div id="version">v{process.env.REACT_APP_VERSION}</div>
    </div>
  );
};

export default Instructions;
