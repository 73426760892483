import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, AccountInfo } from "@azure/msal-browser";
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import Instructions from './components/Instructions';
import { loginRequest } from "./authConfig";
import './App.css'; // Add this to include any custom CSS
import { InteractionRequiredAuthError } from "@azure/msal-browser";


const App: React.FC = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
  const activeAccount: AccountInfo | undefined = instance.getActiveAccount() || undefined;

  if (inProgress === InteractionStatus.None) {
    if (!activeAccount && accounts.length > 0) {
      // Set the first available account as the active account
      instance.setActiveAccount(accounts[0]);
    }

    if (!activeAccount && accounts.length === 0) {
      // Trigger login redirect if no account is available
      instance.loginRedirect(loginRequest).catch((e) => {
        console.error("Login redirect failed", e);
      });
    } else if (activeAccount) {
      // Attempt silent login with active account
      instance.acquireTokenSilent({
        ...loginRequest,
        account: activeAccount,
      })
        .then(() => {
          setIsLoading(false); // Successful silent login
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError || error.errorCode === "login_required") {
            // Fallback to interactive login
            instance.loginRedirect(loginRequest).catch(e => {
              console.error("Login redirect failed", e);
            });
          } else {
            console.error("Silent login failed", error);
          }
        });
    }
  }
}, [inProgress, instance, accounts]);

  
  

  return (
    <Router>
      {isLoading ? (
        <UnauthenticatedTemplate>
          <div className="loading-container">
            <div className="spinner"></div>
            <p>We are authenticating your account. Please wait...</p>
          </div>
        </UnauthenticatedTemplate>
      ) : (
        accounts.length > 0 ? (
          <AuthenticatedTemplate>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/dashboard/:region" element={<Dashboard />} />
              <Route path="/instructions/:region" element={<Instructions />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </AuthenticatedTemplate>
        ) : (
          <UnauthenticatedTemplate>
            <div className="loading-container">
              <p>We are authenticating your account. Please wait...</p>
            </div>
          </UnauthenticatedTemplate>
        )
      )}
    </Router>
  );
}

export default App;
