import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { regionContent, regionApps, apps, RegionId } from '../data/appData';

const Dashboard: React.FC = () => {
  const { region } = useParams<{ region: string }>();
  
  if (!region || !(region in regionContent)) {
    return <div>Region not found</div>;
  }

  const validRegion = region as RegionId;
  const content = regionContent[validRegion];
  const appsForRegion = regionApps[validRegion] || [];

  // Map over the apps and include the category
  const tiles = appsForRegion.map(appId => {
    const app = apps[appId];
    return {
      ...app,
      title: app.title[validRegion],
      description: app.description[validRegion],
      regionId: validRegion,
      category: app.category || 'Uncategorized', // Default category if none is assigned
    };
  });

  // Group apps by category
  const appsByCategory = tiles.reduce((categories, app) => {
    const { category } = app;
    if (!categories[category]) {
      categories[category] = [];
    }
    categories[category].push(app);
    return categories;
  }, {} as Record<string, typeof tiles>);

  return (
    <div>
      <div className="header">
        <img alt="MyBrunel Logo" width="100" height="26" src="/images/brunel_logo.svg" />
      </div>
      <div className="description-container">
        <div className="button-container">
          <Link to="/" className="button">Back</Link>
          <div className="right-buttons">
            <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=efKBNjtF8ESAJnIPTLOL2sDzhTY1rlRErNwz3sgkMRZUNUtIMkc5M0lTSVEwR0xKNjE4RDU4RThDWS4u" target="_blank" rel="noopener noreferrer" className="button">
              Feedback
            </a>

          </div>
        </div>
        <h1>{content.title}</h1>
        <p>{content.description}</p>
        <div className="disclaimer-text">
          <p>{content.disclaimer}</p>
        </div>
        <div className="instructions-text">
          <h1>{content.instructionstitle}</h1>
        <p>{content.instructions}</p>
        <Link to={`/instructions/${validRegion}`} className="button instructions-button">
        Instructions & Tutorials
            </Link>
      </div>
      <div className="app-container">
        {Object.entries(appsByCategory).map(([categoryName, appsInCategory]) => (
          <div key={categoryName} className="category-section">
            <h2>{categoryName}</h2>
            <div className="apps-in-category">
              {appsInCategory.map((tile) => (
                <a key={tile.title} href={tile.link} target="_blank" rel="noopener noreferrer" className="app">
                  <div className="app-header">
                    <h3>{tile.title}</h3>
                    <img src={tile.logoSrc} className="powered-by-logo" alt={tile.logoAlt} />
                  </div>
                  <p>{tile.description}</p>
                  <div className="app-image-container">
                    <img src={tile.imageSrc} className="app-image" alt={tile.title} />
                    {tile.showQRImg.includes(tile.regionId) && tile.imageSrcQr && (
                      <img src={tile.imageSrcQr} className="app-qr" alt={tile.title} />
                    )}
                  </div>
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>
      </div>
      
      <div id="version">v{process.env.REACT_APP_VERSION}</div>
    </div>
  );
};

export default Dashboard;